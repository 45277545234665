<div class="row">
  <table class="table-responsive">
    <table class="table table-sm table-striped table-condensed">
      <thead>
      <tr>
        <th class="text-center">Name</th>
        <th class="text-center">Version</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td class="text-center text-nowrap fw-bold">Build</td>
        <td></td>
      </tr>
      <ng-container *ngFor="let buildInfo of buildInfos">
        <tr>
          <td class="text-center text-nowrap">{{ buildInfo.name }}</td>
          <td class="text-center text-nowrap">{{ buildInfo.version }}</td>
        </tr>
      </ng-container>
      <tr>
        <td class="text-center text-nowrap fw-bold">Server</td>
        <td></td>
      </tr>
      <ng-container *ngFor="let serverInfo of serverInfos">
        <tr>
          <td class="text-center text-nowrap">{{ serverInfo.name }}</td>
          <td class="text-center text-nowrap">{{ serverInfo.version }}</td>
        </tr>
      </ng-container>
      <tr>
        <td class="text-center text-nowrap fw-bold">Client</td>
        <td></td>
      </tr>
      <ng-container *ngFor="let clientInfo of clientInfos">
        <tr>
          <td class="text-center text-nowrap">{{ clientInfo.name }}</td>
          <td class="text-center text-nowrap">{{ clientInfo.version }}</td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </table>
</div>
