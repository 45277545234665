import { Component, OnInit } from '@angular/core';
import { NetworkService } from '../../_services/network.service';

@Component({
  templateUrl: './info.component.html',
  styleUrl: './info.component.css'
})
export class InfoComponent implements OnInit {

  buildInfos: { name: string, version: string }[] = []
  serverInfos: { name: string, version: string }[] = []
  clientInfos: { name: string, version: string }[] = []

  constructor(private networkService: NetworkService) {
    console.info('InfoComponent constructor()');
  }

  ngOnInit() {
    console.info('InfoComponent ngOnInit()');
    this.networkService.getInfos().subscribe(info => {
      this.buildInfos = Array.from(new Map<string,string>(Object.entries(info.get('buildInfos'))), ([key, value]) => ({ name: key, version: value }))
      this.serverInfos = Array.from(new Map<string,string>(Object.entries(info.get('serverInfos'))), ([key, value]) => ({ name: key, version: value }))
      this.clientInfos = Array.from(new Map<string,string>(Object.entries(info.get('clientInfos'))), ([key, value]) => ({ name: key, version: value }))
    })
  }
}
